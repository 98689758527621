<template>
	<div class="404">
		<h1 class="indigo--text text--darken-4 text-center">Efetuando logout...</h1>
	</div>
</template>
<script>
export default {
	data() {
		return {
		}
	},
	created() {
		//removendo dados de sessao da memoria
    	localStorage.removeItem('logado');
    	localStorage.removeItem('token');
		localStorage.removeItem('id_usuario');
		localStorage.removeItem('nome_usuario');
		localStorage.removeItem('id_cargo_usuario');
		localStorage.removeItem('cargo_usuario');
		localStorage.removeItem('cpf');
		
		let thisEl = this;
		setTimeout(function () {
			thisEl.$router.push('/login');
		}, 1000);
	}
};
</script>
